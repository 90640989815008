import request from '@/service/lib/request';


export function getList(data) {
  return request({
    url: '/appuser/appusers',
    method: 'get',
    params: data
  });
}

export function updatePoint(data) {
  return request({
    url: '/appuser/point',
    method: 'post',
    data
  });
}

export function pointRecordList(data) {
  return request({
    url: '/appuser/points',
    method: 'get',
    params: data
  });
}

export function shareRecordList(data) {
  return request({
    url: '/appuser/page_share_records',
    method: 'get',
    params: data
  });
}


export function getComboBox() {
  return request({
    url: '/appuser/appuser_combobox',
    method: 'get'
  });
}