

<template>
  <div class="main-conent main-conent-minheight" v-loading="loading">
    <el-card shadow="never" class="border-none">
      <div slot="header">
        <el-row>
          <el-col :span="16">
            <span class="formLabel">
              转发用户：
            </span>
            <el-select v-model="searchForm.appUserId" clearable placeholder="请选择用户" filterable>
              <el-option v-for="item in appusers" :key="item.id" :label="item.title" :value="item.id"
                :disabled="item.disabled">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="dataBindingInit()">搜索</el-button>
          </el-col>
        </el-row>
      </div>
      <el-table :data="tableData" stripe fit>
        <el-table-column prop="id" label="编号" width="60" />
        <el-table-column prop="id" label="转发用户" width="120">
          <template slot-scope="scope">
            <div class="Middle">
              <el-avatar size="small" :src="scope.row.avatar"></el-avatar>
              <label>{{scope.row.nickName}}</label>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="打开用户" width="120">
          <template slot-scope="scope">
            <div class="Middle">
              <el-avatar size="small" :src="scope.row.openAvatar"></el-avatar>
              <label>{{scope.row.openNickName}}</label>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="fromPageName" label="文章/圈子来源" />
        <el-table-column prop="title" label="标题" />
        <el-table-column prop="createTime" label="打开时间" />
      </el-table>
      <div class="margin-t16 text-right">
        <el-pagination background layout="prev, pager,next" :total="totalCount" :current-page="searchForm.page"
          :page-size="searchForm.pageSize" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { shareRecordList, getComboBox } from '@/service/appuser.js';

export default {
  data() {
    return {
      loading: false,
      searchOpen: false,
      totalCount: 0,
      searchForm: {
        page: 1,
        pageSize: 20,
        appUserId: ''
      },
      tableData: [],
      appusers: []
    };
  },
  created() {
    this.comboBoxBinding();
    this.dataBinding();
  },
  methods: {
    //加载页面数据
    dataBinding() {
      this.loading = true;
      shareRecordList(this.searchForm).then(res => {
        console.log(res);
        this.totalCount = res.total;
        this.tableData = res.list;
        this.loading = false;
      });
    },
    //获取下拉数据
    comboBoxBinding() {
      getComboBox().then(cob => {
        this.appusers = cob;
      });
    },
    //清空页码
    dataBindingInit() {
      this.searchForm.page = 1;
      this.dataBinding();
    },
    //页码切换
    handleCurrentChange(val) {
      if (this.searchForm.page != val) {
        this.searchForm.page = val;
        this.dataBinding();
      }
    }

  }
};
</script>
<style scoped>
.Middle {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.formLabel {
  font-size: 15px;
  color: gray;
  margin: 0;
  padding: 0;
}
</style>